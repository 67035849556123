/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Badge from "@material-ui/core/Badge";
import { useTranslation } from "react-i18next";
import moment from "moment";
import APIService from "api/APIService";
import { formatDateTime } from "api/utils/utils";
import ListsWidget from "../../../../../app/pages/components/ListsWidget";
import { isObjectEmpty } from "api/utils/utils";
import ApiStorage from "../../../../../app/ApiStorage";

export function UserNotificationsDropdown() {
  const { t } = useTranslation();

  const { eventLog, selectedUbn } = useSelector(
    (state) => ({
      eventLog: state.dashboard.eventLogResponse,
      selectedUbn: state.login.selectedUbn,
    }),
    shallowEqual
  );
  const [filteredEventLog, setFilteredEventLog] = useState([]);
  const [lastSeenEventsDate, setLastSeenEventsDate] = useState({});
  const [profile, setProfile] = useState({});
  const [id, setId] = useState("");

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(userData);
        const { userProfile } = transformedData;
        setProfile(userProfile);
      } catch (error) {
        console.error(error);
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      try {
        const response = await APIService.getSingleLookup({
          id,
        });
        setLastSeenEventsDate(response);
      } catch (e) {
        console.error(e.message);
      }
    };
    !isObjectEmpty(profile) && id.length > 0 && apiCall();
  }, [eventLog, id, profile]);

  useEffect(() => {
    if (!isObjectEmpty(profile) && !isObjectEmpty(selectedUbn)) {
      setId(`lastSeenEventsDate_${profile._id}_${selectedUbn.name}`);
    }
  }, [profile, selectedUbn]);

  useEffect(() => {
    const apiCall = async () => {
      const events = await APIService.getEvents({
        limit: 500,
        sort: "-createdAt",
        "filter[createdAt][$gt]": !isObjectEmpty(lastSeenEventsDate)
          ? lastSeenEventsDate.value
          : moment().subtract(1, "days").toISOString(),
      });
      setFilteredEventLog(
        events?.data?.filter(
          (log) => log.operationType === "consolidate" && log.tx.agree === false
        )
      );
    };

    !isObjectEmpty(profile) &&
      profile.services.find((s) => s.type === "UBN") &&
      apiCall();
  }, [lastSeenEventsDate, profile]);

  const apiCall = async () => {
    try {
      await APIService.upsertLookup({
        id,
        type: "STATE",
        access: "PRIVATE",
        value: eventLog.data[0].createdAt,
      });
      setFilteredEventLog([]);
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleOnClick = (show) => {
    if (show && isObjectEmpty(lastSeenEventsDate)) {
      // we hovered over the notification and we have seen them, state lastSeen not yet set
      filteredEventLog.length > 0 &&
        !isObjectEmpty(eventLog) &&
        eventLog.data.length > 0 &&
        apiCall();
    } else if (show && !isObjectEmpty(lastSeenEventsDate)) {
      // don't update lastSeen state if we scroll recentEvents component and get old data
      filteredEventLog.length > 0 &&
        !isObjectEmpty(eventLog) &&
        eventLog.data.length > 0 &&
        eventLog.data[0].createdAt > lastSeenEventsDate.value &&
        apiCall();
    }
  };

  return (
    <div className="topbar-item">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="user-notification-tooltip">
            <div>
              {filteredEventLog.length > 0
                ? filteredEventLog.map((event, index) => (
                    <ListsWidget
                      key={index}
                      margin={"mb-1"}
                      title={formatDateTime(
                        event.timestampTx ? event.timestampTx : event.createdAt
                      )}
                      content={
                        <>
                          {event.operationType === "consolidate" && (
                            <>{`${t("declined")} ${event.root?.title}`}</>
                          )}
                        </>
                      }
                      color="secondary"
                      showIcon={false}
                    />
                  ))
                : t("no-new-notifications")}
            </div>
          </Tooltip>
        }
      >
        <button
          className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
          id="kt_quick_notifications_toggle"
          onClick={handleOnClick}
        >
          <span className="svg-icon svg-icon-xl svg-icon-primary">
            <Badge badgeContent={filteredEventLog?.length} color="primary">
              <NotificationsNoneIcon color="primary" />
            </Badge>
          </span>

          {filteredEventLog.length > 0 && (
            <>
              <span className="pulse-ring"></span>
              <span className="pulse-ring"></span>
            </>
          )}
        </button>
      </OverlayTrigger>
    </div>
  );
}
