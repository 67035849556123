import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import APIService from "api/APIService";
import ReCAPTCHA from "react-google-recaptcha";
import Typography from "@material-ui/core/Typography";

const initialValues = {
  name: "",
  email: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  let captcha;

  const [loading, setLoading] = useState(false);
  const [recaptchaEnabled, setRecaptchaEnabled] = useState(false);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  // const recaptchaRef = React.createRef();

  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),

    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    changepassword: Yup.string()
      .required()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  useEffect(() => {
    const apiCall = async () => {
      try {
        const { captchaEnabled, captchaSiteKey } =
          await APIService.getPublicSystemConfig({});
        setRecaptchaEnabled(captchaEnabled);
        setRecaptchaSiteKey(captchaSiteKey);
      } catch (e) {
        console.error(e.message);
      }
    };
    apiCall();
  }, []);

  const getFrontendUrl = () => {
    const url = new URL(`${APIService.provenanceFrontendURL}auth/login`);
    return url.toString();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      const recaptchaValid =
        !recaptchaEnabled || (recaptchaEnabled && !!recaptchaValue);
      if (!recaptchaValid) {
        setStatus("Invalid captcha");
        setSubmitting(false);
        return;
      }
      enableLoading();
      setTimeout(() => {
        APIService.createUser({
          name: values.name,
          email: values.email,
          password: values.password,
          // companyName: validOrganization,
          // companyPosition,
          services: APIService.defaultRegistrationServices,
          frontendUrl: getFrontendUrl(),
          roles: APIService.defaultRegistrationRoles,
          enabledMagicLinkLogin: true,
          trialAccount: true,
          notifyLowCredits: true,
          lowCreditsThreshold: "0",
          recaptchaValue,
        })
          .then((data) => {
            // props.register(accessToken);
            disableLoading();
            setSuccessMessage("User created");
            setSubmitting(false);
          })
          .catch((e) => {
            setSubmitting(false);
            setStatus(e.message);
            disableLoading();
          });
      }, 1000);
    },
  });

  return (
    <>
      {successMessage ? (
        <div className="login-form login-signin" style={{ display: "block" }}>
          <Typography variant="h6">{`Dear user,`}</Typography>
          <Typography variant="h6" mt={2}>
            {`Thank you for signing up to GIM! We're thrilled to have you
          on board. To ensure the security of your account and enable all the features, we kindly
          request you to verify your email address. You should have received an email with a
          verification link. Please click on the link to complete the registration process. If you
          haven't received the email, please check your spam or junk folder. Occasionally, it may
          take a few minutes for the email to arrive. If you encounter any issues or need further
          assistance, feel free to contact us at admin@unisot.id, we will be happy to assist
          you. `}
          </Typography>
          <Typography variant="h6" mt={2}>
            Best regards,
          </Typography>
          <Typography variant="h6" mt={2}>
            The UNISOT Team
          </Typography>
        </div>
      ) : (
        <div className="login-form login-signin" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Register</h3>
            <p className="text-muted font-weight-bold">
              Enter your details to create your account
            </p>
          </div>

          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* end: Alert */}

            {/* begin: Fullname */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "fullname"
                )}`}
                name="name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              ) : null}
            </div>
            {/* end: Fullname */}

            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Confirm Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "changepassword"
                )}`}
                name="changepassword"
                {...formik.getFieldProps("changepassword")}
              />
              {formik.touched.changepassword && formik.errors.changepassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.changepassword}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}

            {/* begin: Terms and Conditions */}
            <div className="form-group">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  className="m-1"
                  {...formik.getFieldProps("acceptTerms")}
                />
                <a
                  href="https://unisot.com/terms-and-conditions/"
                  target="_blank"
                  className="mr-1"
                  rel="noopener noreferrer"
                >
                  I agree the Terms & Conditions
                </a>
                <span />
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.acceptTerms}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Terms and Conditions */}
            <div className="form-group">
              {recaptchaEnabled && (
                <ReCAPTCHA
                  ref={(el) => {
                    captcha = el;
                  }}
                  sitekey={recaptchaSiteKey}
                  onChange={(value) => {
                    setRecaptchaValue(value);
                  }}
                />
              )}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.values.acceptTerms
                }
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>Submit</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>

              <Link onClick={() => (window.location.href = "/auth/login")}>
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default connect(null, auth.actions)(Registration);
